import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	inject,
} from "@angular/core";
import { ActivatedRoute, Data, Router } from "@angular/router";
import { Subscription, filter, map, mergeMap } from "rxjs";

@Component({
	selector: "cp-header",
	templateUrl: "./header.component.html",
	styleUrl: "./header.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
	@Input() mode: "page" | "navigation" = "page";
	@Input() showMenuButton = false;
	@Input() showCloseButton = false;
	@Input() showRentalSwitcher = false;

	@Output() readonly menuToggle = new EventEmitter<boolean>();

	router = inject(Router);
	activatedRoute = inject(ActivatedRoute);
	private changeDetectorRef = inject(ChangeDetectorRef);

	title = "";
	useSearchToolbar = false;
	showHeader = true;

	private routerEventsSubscription!: Subscription;

	ngOnInit() {
		this.subscribeToRouterEvents();
	}

	ngOnDestroy() {
		this.routerEventsSubscription?.unsubscribe();
	}

	toggleMenu(isOpen: boolean): void {
		this.menuToggle.emit(isOpen);
	}

	private subscribeToRouterEvents(): void {
		this.routerEventsSubscription = this.router.events
			.pipe(
				map(() => this.activatedRoute),
				map((route) => {
					while (route.firstChild) {
						route = route.firstChild;
					}
					return route;
				}),
				filter((route) => route.outlet === "primary"),
				mergeMap((route) => route.data)
			)
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			.subscribe((data: Data) => {
				this.title = data["title"] || "";
				this.useSearchToolbar = data["useSearch"] !== undefined ? data["useSearch"] : this.useSearchToolbar;
				this.showHeader = data["showHeader"] !== undefined ? data["showHeader"] : this.showHeader;
				this.changeDetectorRef.markForCheck();
			});
	}
}
