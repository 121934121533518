import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { SelectInputModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/inputs/select-input";
import { RentalsModule } from "@e-tenant-hub/shared/rentals";
import { RentalSwitcherComponent } from "./rental-switcher.component";

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, RentalsModule, SelectInputModule],
	declarations: [RentalSwitcherComponent],
	exports: [RentalSwitcherComponent],
})
export class RentalSwitcherModule {}
