@if (showHeader) {
	<mat-toolbar
		class="flex-col"
		[ngClass]="{
			'!bg-white/38': mode === 'page',
			'!bg-transparent': mode === 'navigation'
		}"
	>
		<mat-toolbar-row
			class="!px-6 !pb-4 !pt-12"
			[ngClass]="{
				'lg:!px-12 lg:!py-9 xl:bg-right xl:bg-no-repeat': mode === 'page',
				'lg:!py-8': mode === 'navigation'
			}"
		>
			<div class="flex p-0">
				<div class="flex flex-1 justify-start lg:hidden">
					@if (showMenuButton) {
						<cp-icon-button
							class="text-primary"
							[icon]="'sort'"
							(clicked)="toggleMenu(true)"
						></cp-icon-button>
					}
				</div>

				@if (mode === "navigation") {
					<div class="flex flex-auto items-center justify-center">
						<!-- LOGO -->
						<img src="/assets/images/emh_logo.svg" alt="logo EMH" />
					</div>
				}

				@if (mode === "page") {
					<div class="flex flex-auto items-center justify-center lg:justify-start">
						<h1 class="basis-0 text-center text-neutral-800">
							{{ title | uppercase }}
						</h1>
					</div>
				}

				@if (showRentalSwitcher) {
					<cp-rental-switcher
						class="sm:1/3 inline-flex w-1/2 flex-col items-center justify-center lg:w-2/6 xl:w-1/4"
						fill="solid"
					></cp-rental-switcher>
				}
				<div class="flex flex-1 items-center justify-end gap-2 lg:hidden">
					@if (showCloseButton) {
						<cp-icon-button
							class="text-primary"
							[icon]="'close'"
							(clicked)="toggleMenu(false)"
						></cp-icon-button>
					}
				</div>
			</div>
		</mat-toolbar-row>
		@if (mode === "page" && useSearchToolbar) {
			<mat-toolbar-row class="!px-6 !py-4 lg:!px-12 lg:!py-6">
				<cp-header-search-toolbar></cp-header-search-toolbar>
			</mat-toolbar-row>
		}
	</mat-toolbar>
}
