import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { SelectInputOption } from "@e-tenant-hub/client-platform/shared/ui/responsive/inputs/select-input";
import { AuthService } from "@e-tenant-hub/shared/auth";
import { CommunicationService, RentalUnitResponse, RentalsService } from "@e-tenant-hub/shared/rentals";
import { CacheKeys, CacheStorageService } from "@e-tenant-hub/shared/utils";
import { Observable, tap } from "rxjs";

@Component({
	selector: "cp-rental-switcher",
	templateUrl: "./rental-switcher.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RentalSwitcherComponent implements OnInit {
	@Input() fill: "solid" | "translucid" = "translucid";

	@Output() readonly selectionChanged = new EventEmitter();

	private readonly cacheStorageService = inject(CacheStorageService);
	private readonly communicationService = inject(CommunicationService);
	private readonly formBuilder = inject(FormBuilder);
	private readonly rentalsService = inject(RentalsService);
	private readonly authService = inject(AuthService);
	private readonly user = this.authService.user;

	form!: FormGroup;
	rentalUnitOptions!: SelectInputOption[];

	getRentals$!: Observable<RentalUnitResponse[]>;

	ngOnInit(): void {
		if (this.user.isImpersonated) {
			this.getRentals$ = this.rentalsService.getRentalsByIds(this.user?.rentalIds).pipe(
				tap(
					(rentals) =>
						(this.rentalUnitOptions = rentals.map(
							(unit) =>
								({
									value: unit.rentalIdentifier,
									label: `${unit.name} - ${unit.rentalIdentifier}`,
								}) as SelectInputOption
						))
				),
				tap(() => this.createForm())
			);
			return;
		}

		this.getRentals$ = this.rentalsService.getRentalsByUsername(this.user?.username).pipe(
			tap(
				(rentals) =>
					(this.rentalUnitOptions = rentals.map(
						(unit) =>
							({
								value: unit.rentalIdentifier,
								label: `${unit.name} - ${unit.rentalIdentifier}`,
							}) as SelectInputOption
					))
			),
			tap(() => this.createForm())
		);
	}

	createForm() {
		const currentItem = this.rentalUnitOptions.find((i) => i.value === this.user.currentRentalId);
		this.form = this.formBuilder.group({
			contract: [currentItem ? currentItem : this.rentalUnitOptions[0]],
		});
	}

	handleValueChange($event: MatSelectChange) {
		const newRentalUnitId = ($event.value as SelectInputOption).value as string;
		this.user.currentRentalId = newRentalUnitId;
		this.cacheStorageService.saveCache<string>(CacheKeys.RENTAL_SWITCHER_SELECTION, this.user.currentRentalId);
		this.communicationService.changeRentalUnitSelection();
		this.selectionChanged.emit();
	}
}
