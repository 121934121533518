import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	Output,
	ViewChild,
	inject,
} from "@angular/core";

import { Router } from "@angular/router";
import { AuthService, Impersonation } from "@e-tenant-hub/shared/auth";
import { NavItem } from "./nav-item/nav-item.type";

@Component({
	selector: "cp-nav-bar",
	templateUrl: "./nav-bar.component.html",
	styleUrl: "./nav-bar.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarComponent {
	@Input() navItems?: NavItem[];
	@Output() readonly navItemClicked = new EventEmitter<string>();

	isMenuOpen = false;

	private readonly authService = inject(AuthService);
	readonly user = this.authService.user;
	private readonly router = inject(Router);

	@ViewChild("menuWrapper") menuWrapper!: ElementRef;
	@ViewChild("submenu") submenu!: ElementRef;

	handleNavItemClick() {
		this.navItemClicked.emit();
	}

	openCloseMenu(event: MouseEvent): void {
		event.stopPropagation();
		this.isMenuOpen = !this.isMenuOpen;
	}
	@HostListener("document:click", ["$event"])
	closeSubmenuOnClickOutside(event: MouseEvent): void {
		if (
			this.isMenuOpen &&
			this.submenu &&
			this.menuWrapper &&
			!this.menuWrapper.nativeElement.contains(event.target) &&
			!this.submenu.nativeElement.contains(event.target)
		) {
			this.isMenuOpen = false;
		}
	}

	async logout(): Promise<void> {
		if (this.user?.isImpersonated) Impersonation.resetImpersonationInsideCache();
		await this.authService.logout();
	}

	resetImpersonation(): void {
		Impersonation.resetImpersonationInsideCache();
		this.router.navigateByUrl("/choose-account");
	}
}
