@if (getRentals$ | async; as getRentals) {
	@if (rentalUnitOptions.length > 1) {
		<form class="flex w-full flex-col" [formGroup]="form">
			<cp-select-input
				[multiple]="false"
				[fill]="fill"
				[options]="rentalUnitOptions"
				(selectionChanged)="handleValueChange($event)"
				formControlName="contract"
			></cp-select-input>
		</form>
	}
}
