@if (isSmartphone$ | async) {
	<div class="inline-flex grow basis-0 flex-wrap items-start justify-start gap-4 self-stretch">
		<cp-search-solicitations-form class="grow-[2]" [showAdditionalFilters]="false"></cp-search-solicitations-form>
		<cp-icon-button
			class="text-2xl text-neutral-400"
			[icon]="'filter_list'"
			[isActive]="(hasFilters$ | async) ?? false"
			(clicked)="openSolicitationsFiltersModal()"
		></cp-icon-button>
	</div>
} @else {
	<cp-search-solicitations-form></cp-search-solicitations-form>
}
@if (isSmartphone$ | async) {
	@if (solicitationsService.isNewSolicitationRequestDisabled$ | async) {
		<cp-rounded-button
			class="self-stretch"
			[icon]="'add'"
			[isIconFilled]="false"
			[isFullWidth]="true"
			[label]="'nouvelle demande'"
			[readOnly]="true"
			(clicked)="openSolicitationCreationModal(true)"
		></cp-rounded-button>
	} @else {
		<cp-rounded-button
			class="self-stretch"
			[icon]="'add'"
			[isIconFilled]="false"
			[isFullWidth]="true"
			[label]="'nouvelle demande'"
			[readOnly]="false"
			(clicked)="openSolicitationCreationModal(false)"
		></cp-rounded-button>
	}
}
