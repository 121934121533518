<div
	class="inline-flex flex-col items-center justify-center gap-6 bg-white bg-opacity-40 px-6 py-4 sm:absolute sm:bottom-0 sm:px-12"
	[ngClass]="{
		'w-screen': mode === 'over',
		'w-full': mode === 'side'
	}"
>
	<div
		class="flex w-full flex-col items-center justify-center gap-4 self-stretch sm:inline-flex sm:flex-row sm:justify-between"
	>
		<a
			class="hover:text-primary font-futura text-sm font-medium text-neutral-400"
			href="/legal-notice"
			target="_blank"
			>Mentions légales & CGU</a
		>
		<a
			class="hover:text-primary font-futura text-sm font-medium text-neutral-400"
			href="/cookies-policy"
			target="_blank"
			>Données personnelles</a
		>
		<a
			class="hover:text-primary font-futura cursor-pointer text-sm font-medium text-neutral-400"
			(click)="openCookiesModal()"
			>Gérer les cookies</a
		>
		<div class="font-futura text-sm font-medium text-neutral-400">© 2024 EMH</div>
		<div class="inline-flex items-start justify-start gap-4">
			<a
				class="hover:text-primary text-neutral-400"
				href="https://fr.linkedin.com/company/est-m%C3%A9tropole-habitat/"
				target="_blank"
			>
				<img class="h-6 w-6" src="/assets/images/linkedin.svg" />
			</a>
			<a
				class="hover:text-primary text-neutral-400"
				href="https://fr-fr.facebook.com/EstMetropoleHab/"
				target="_blank"
			>
				<img class="h-6 w-6" src="/assets/images/facebook.svg" />
			</a>
			<a
				class="hover:text-primary text-neutral-400"
				href="https://www.youtube.com/user/EstMetropoleHabitat"
				target="_blank"
			>
				<img class="h-6 w-6" src="/assets/images/youtube.svg" />
			</a>
			<a class="hover:text-primary text-neutral-400" href="https://x.com/EstMetropoleHab" target="_blank">
				<img class="h-6 w-6" src="/assets/images/twitter.svg" />
			</a>
		</div>
	</div>
</div>
